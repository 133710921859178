<template>
  <!-- 布局组件 -->
  <component
    v-if="layoutComponentsConfig[record.type]"
    :is="layoutComponentsConfig[record.type].componentLevel"
    :layout-info="layoutComponentsConfig[record.type]"
    :record="record"
  >
    <template slot-scope="{content}">
      <buildBlock
        v-for="(item, index) in content.list"
        v-bind="$attrs"
        v-on="$listeners"
        :key="index"
        :record="item"
      >
        <template v-slot="slotProps">
          <slot :record="slotProps.record"/>
        </template>
      </buildBlock>
    </template>
  </component>

  <!-- 表单元素 -->
  <ax-form-item
    v-else
    v-bind="$attrs"
    v-on="$listeners"
    :record="record"
  >
    <template v-slot="slotProps">
      <slot :record="slotProps.record"/>
    </template>
  </ax-form-item>
</template>

<script>
import oneLevel from './form-layout/one-level'
import twoLevels from './form-layout/two-levels'
import threeLevels from './form-layout/three-levels'
import axFormItem from './form-elements/form-item'
import layoutComponentsConfig from './form-layout/layout-components-config'
export default {
  name: 'BuildBlock',
  components: { oneLevel, twoLevels, threeLevels, axFormItem },
  props: {
    record: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data () {
    return {
      layoutComponentsConfig
    }
  }
}
</script>
