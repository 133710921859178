const layoutComponentsConfig = {
  tabs: {
    componentLevel: 'twoLevels',
    component: 'a-tabs',
    specialProps: record => {
      return {
        ...record.options,
        type: record.options?.type
      }
    },
    subNode: {
      component: 'a-tab-pane',
      itemKey: 'tabPanes',
      specialProps: record => record
    }
  },

  collapse: {
    componentLevel: 'twoLevels',
    component: 'a-collapse',
    specialProps: record => record.options,
    subNode: {
      itemKey: 'panels',
      component: 'a-collapse-panel',
      specialProps: record => record
    }
  },

  grid: {
    componentLevel: 'twoLevels',
    component: 'a-row',
    specialProps: record => {
      return {
        ...record.options,
        type: record.options?.type
      }
    },
    subNode: {
      itemKey: 'cols',
      component: 'a-col',
      specialProps: record => record
    }
  },

  card: {
    componentLevel: 'oneLevel',
    component: 'a-card',
    specialProps: record => {
      return {
        ...record.options,
        title: record.label
      }
    }
  },

  table: {
    componentLevel: 'threeLevels',
    component: 'table',
    specialProps: () => {},
    subNode: {
      itemKey: 'trs',
      component: 'tr',
      specialProps: () => {},
      subNode: {
        itemKey: 'tds',
        component: 'td',
        specialProps: () => {}
      }
    }
  }
}

export default layoutComponentsConfig
