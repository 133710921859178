<template>
  <component
    v-bind="layoutInfo.specialProps(record)"
    :is="layoutInfo.component"
  >
    <component
      v-for="(twoLevelItem, index) in record[layoutInfo.subNode.itemKey]"
      v-bind="layoutInfo.subNode.specialProps(twoLevelItem)"
      :is="layoutInfo.subNode.component"
      :key="index"
    >
      <slot :content="twoLevelItem"/>
    </component>
  </component>
</template>

<script>
export default {
  name: 'TwoLevels',
  props: {
    layoutInfo: {
      type: Object,
      default: () => ({})
    },
    record: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
